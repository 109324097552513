<template>
  <div>
    <a-modal v-model="visible" width="600px" :title="'审批操作 (' + (form.step_status === '100' ? '通过' : '不通过') + ')'" @ok="handleOk">
      <p>填写意见<span></span></p>
      <a-textarea
        v-model="form.step_remark"
        placeholder=""
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>

import { fetch } from '@/utils/request'
export default {
  name: "approvalModal",
  props: {
  },
  data() {
    return {
      visible: false,
      form: {
        prjoid: "",
        deliveryoid: "",
        delivery_type: "",
        step_seq: "",
        step_remark: "",
        step_status: "",
        is_end: "",
      }
    }
  },
  created() {

  },
  computed: {
  
  },
  methods: {
    open(step_status, row) {
      this.form.prjoid = row.prjoid
      this.form.deliveryoid = row.oid
      this.form.delivery_type = row.delivery_type
      this.form.step_seq = row.step_seq
      this.form.is_end = row.is_end
      this.form.step_remark = ""
      this.form.step_status = step_status
      console.log(this.form)
      this.visible = true
    },
    handleOk(){
      fetch('POST', '/prj/wfdelivery/create',this.form).then((res) => {
        if (res.status === 0) {
          console.log('details', res)
          this.$message.success('审批成功！')
          this.$emit('change')
        } else {
          this.$message.error(res.message)
        }
      })
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-title{
font-size: 18px;
font-weight: 500;
}
p{
  font-size: 18px;
  font-weight: 400;
  span{
    font-size: 14px;
    color: #919191;
  }
}
</style>
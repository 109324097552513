<template>
  <div class="app-main-content">
    <card :tabs="cat.delivery_status" v-model="listQuery.delivery_status" body-class="overflow-hidden page-card-body"
          @change="search">
      <template #tools>
        <div class="right">
          <div class="item">
            <a-input placeholder="请输入标题" v-model="listQuery.keywords" @change="search" style="width: 180px">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
        </div>
      </template>
      <div class="body-flex">
        <div class="body-left-table">
          <a-table
              rowKey="oid"
              style="min-width: 1080px"
              :data-source="list"
              :pagination="pagination"
              @change="paginationChange"
              :customRow="customRow"
          >
            <a-table-column title="ID" data-index="seqid" width="70px">
              <template #default="seqid">
                {{ seqid }}
              </template>
            </a-table-column>
            <a-table-column title="交付物名称" min-width="300px" data-index="delivery_name">
              <template #default="delivery_name, row">
                <span style="color: #0088cc;cursor: pointer;" @click.stop="$preview(row.att, true)">{{
                    delivery_name
                  }}</span>
              </template>
            </a-table-column>
            <a-table-column title="审核状态" width="120px" data-index="delivery_status_name">
              <template #default="delivery_status_name">
                {{ delivery_status_name }}
              </template>
            </a-table-column>
            <a-table-column title="类型" data-index="delivery_type_name" width="140px"></a-table-column>
            <a-table-column title="负责人" width="200px">
              <template slot-scope="scope,row">
                {{ row.delivery_user_title ? row.delivery_user + '-' + row.delivery_user_title : row.delivery_user }}
              </template>
            </a-table-column>
            <a-table-column :sorter="true" :sortOrder="createTimeOrder" title="提交时间" data-index="create_time"
                            width="120px">
            </a-table-column>
            <a-table-column title="关联任务" data-index="issue_name" width="180px"></a-table-column>
            <a-table-column title="操作" width="130px">
              <template slot-scope="scope,row">
              <span v-if="row.is_host" style="cursor: pointer" class="blue" @click.stop="passModal('100',row)">
                通过
              </span>
                <span v-if="row.is_host" style="cursor: pointer" class="blue" @click.stop="passModal('-1',row)">
                不通过
              </span>
              </template>
            </a-table-column>
          </a-table>
        </div>
        <div class="body-right-info edp-right-detail" v-if="hasDetail">
          <a-tabs>
            <a-tab-pane key="1" tab="基本信息">
              <div class="info-scroll-content">
                <div class="content-head">
                  <div class="content-head-id">
                    <img :src="$toUrl('product_inventory_blue.png')" alt="">
                    <span class="id">#{{ detail.seqid }}</span>
                  </div>
                  <div class="action" style="margin-left: auto;font-size: 14px;cursor: pointer;"
                       @click="deleteApproval">删除
                  </div>
                </div>
                <div class="title">{{ detail.delivery_name }}</div>
                <div class="scroll-content-status">
                  <div class="status-item">
                    <img class="round" :src="detail.delivery_user_pic" alt="">
                    <div class="status-item-right">
                      <div class="status-title">
                        {{ detail.delivery_user }}
                      </div>
                      <div class="status-sub-text">负责人</div>
                    </div>
                  </div>
                  <div class="status-item">
                    <img class="round" v-if="detail.delivery_status === '-1'" :src="$toUrl('task_status_red.png')"
                         alt="">
                    <img class="round" v-else-if="detail.delivery_status === '10'" :src="$toUrl('task_status_blue.png')"
                         alt="">
                    <img class="round" v-else-if="detail.delivery_status === '100'"
                         :src="$toUrl('task_status_green.png')" alt="">
                    <img class="round" v-else :src="$toUrl('task_status_yellow.png')" alt="">
                    <div class="status-item-right">
                      <div class="status-title">{{ detail.delivery_status_name }}</div>
                      <div class="status-sub-text">状态</div>
                    </div>
                  </div>
                  <div class="status-item">
                    <img class="round" :src="$toUrl('level.png')" alt="">
                    <div class="status-item-right">
                      <div class="status-title">{{ detail.delivery_type_name }}</div>
                      <div class="status-sub-text">类型</div>
                    </div>
                  </div>
                  <div class="status-item" v-if="detail.issue_name">
                    <img class="round" :src="$toUrl('tophase.png')" alt="">
                    <div class="status-item-right">
                      <div class="status-title">{{ detail.issue_name }}</div>
                      <div class="status-sub-text">关联任务</div>
                    </div>
                  </div>
                </div>
                <div class="title" style="font-size: 16px">
                  属性
                </div>
                <div class="content-group-info">
                  <!--              <div class="group-info-title">属性</div>-->
                  <div class="group-info-list">
                    <div class="info-item">
                      <div class="info-key">提交时间</div>
                      <div class="info-value">{{ detail.create_time }}</div>
                    </div>
                    <div class="info-item">
                      <div class="info-key">开始审批</div>
                      <div class="info-value">{{ detail.approval_time }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="审批流程">
              <div class="tabs-content">
                <approvalList :approval-data="detail"></approvalList>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </card>

    <approvalModal ref="approvalModal" @change="getList"></approvalModal>
  </div>
</template>

<script>
import approvalModal from "@/components/approvalModal";
import listMixin from "@/utils/mixins/listMixin.js";
import catMixin from "@/utils/mixins/catMixin.js";
import { fetch } from "@/utils/request.js";
import approval from "@/components/approval.vue";

export default {
  components: { approvalModal, approvalList: approval },
  mixins: [listMixin, catMixin],
  data() {
    return {
      listMixinOptions: {
        autoRun: false,
        url: "/prj/delivery/approval",
        mockUrl: "http://127.0.0.1:4523/m1/2234408-0-default/prj/delivery/approval"
      },
      hasDetail: false,
      detail: {},
      cat: {
        delivery_status: []
      },
      listQuery: {
        keywords: "",
        orderby: "",
        delivery_status: ""
      },
      selectedRowKeys: [],
      createTimeOrder: false,
      detailList: [],
    }
  },
  mounted() {
    if (this.$route.query.oid) {
      let data = sessionStorage.getItem("edp-delivery")
      if (data) {
        data = JSON.parse(data)
        if (data.oid === this.$route.query.oid) {
          this.detail = data
          this.hasDetail = true
        }
      }
    }
    this.getList()
  },
  methods: {
    deleteApproval() {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/delivery/delete", { oid: this.detail.oid }).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.search()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.hasDetail = true
            this.detail = record
          }
        }
      }
    },
    setSortQuery(sort) {
      this.createTimeOrder = sort.order
      if (sort.order === "descend") {
        this.listQuery.orderby = sort.field + ' desc'
      } else if (sort.order === "ascend") {
        this.listQuery.orderby = sort.field + ' asc'
      } else {
        this.listQuery.orderby = ""
      }
    },
    selectActionMenu({ key }) {
      alert("点击了key:" + key)
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    },
    passModal(val, row) {
      this.$refs.approvalModal.open(val, row)
    },
  }
}
</script>

<style lang="less" scoped>
.blue {
  color: #08f;
  margin-right: 8px;
}

/deep/ .ant-dropdown-button {
  > button {
    background-color: #0088FF;
    color: #FFF;
    border-color: #FFF;
  }

  margin-right: 10px;
}

.app-main-content {

  /deep/ .page-card-body {
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;

    // 表格上方的操作栏
    > .body-action {
      display: flex;
      border-bottom: 1px solid #eee;

      .left {
        span {
          margin-left: 11px;
          font-size: 16px;

        }
      }

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 20px;

        .item {
          margin-left: 20px;

          .label {
            margin-right: 10px;
          }
        }
      }
    }

    .condition {
      display: flex;
      align-items: center;
      height: 54px;

      span {
        font-size: 16px;
        color: #4D4D4D;
        font-weight: 400;
      }

      .condition_input {
        width: 172px;

        height: 34px;
        margin: 0 15px;
      }

      .condition_clear {
        font-size: 16px;
        color: #0088FF;
      }

    }

    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        background: #FFF;
      }

      // 右侧信息
      .body-right-info {
        flex-shrink: 0;
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        border-top: 1px solid #ededed;
        background-color: #FFF;

        .ant-tabs-bar {
          margin: 0 0 24px 0;
        }

        // 可以滚动的部分
        .info-scroll-content {
          padding-right: 23px;
          overflow-y: auto;
          padding-bottom: 10px;
          padding-left: 20px;
          // 头部
          .content-head {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .anticon {
              margin-right: 5px;
              font-size: 16px;
            }

            .content-head-id {
              display: flex;
              align-items: center;

              .anticon {
                color: #36A3FF;
              }

              > img {
                display: inline-block;
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 8px;
              }

              .id {
                color: #4D4D4D;
                font-size: 12px;
              }
            }

            .content-head-icons {
              margin-left: auto;
            }
          }

          // 标题
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            color: #4D4D4D;
          }

          // 两列的状态
          .scroll-content-status {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 24px;
            margin-top: 13px;
            margin-bottom: 35px;

            .status-item {
              display: flex;
              align-items: center;

              .round {
                //background-color: #D4D4D4;
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .status-item-right {
                line-height: 1;
                margin-left: 11px;

                .status-title {
                  font-size: 16px;
                  color: #4D4D4D;
                  margin-bottom: 4px;
                }

                .status-sub-text {
                  color: #A7A7A7;
                  font-size: 12px;
                }
              }
            }
          }

          // 描述输入框 后期会换成富文本编辑框
          .description {
            width: 100%;
            height: 150px;
            margin-top: 15px;
            //border: 1px solid #EDEDED;
          }

          // 文本信息展示
          .content-group-info {
            padding-top: 20px;
            // 每组信息的标题
            .group-info-title {
              margin-top: 34px;
              font-size: 18px;
              color: #4D4D4D;
              margin-bottom: 15px;
            }

            // 每组信息列表
            .group-info-list {
              .info-item {
                display: flex;
                color: #4D4D4D;
                font-size: 16px;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                .info-key {
                  width: 150px;
                }
              }

              //动态和评论
              .container-tabs {
                height: 100%;

                .ant-tabs-nav .ant-tabs-tab {
                  margin: 0;
                }

                .ant-tabs-bar {
                  margin: 0;
                }

                .ant-tabs-content {
                  height: 100%;
                }


              }
            }
          }
        }

        .tabs-content {
          height: 100%;

          .dynamic-items {
            padding: 13px;
            align-items: baseline;

            .info {
              padding-left: 14px;
              font-size: 12px;
              color: #909090;

              .name {
                color: #000000;
                margin-bottom: 9px;

                > span {
                  color: #909090;
                }
              }

              .detail {
                .icon {
                  transform: rotate(-90deg);
                  color: #909090;
                }

                > div {
                  margin-right: 7px;
                }

                > div:nth-last-child(1) {
                  margin: 0;
                }

                .finish {
                  color: #24B47E;
                  background-color: #E9F7F2;
                }

                .unfinish {
                  color: #EA0D0D;
                  background: #FDE6E6;
                }
              }
            }
          }

          .detail-title {
            height: 55px;
            line-height: 55px;
            box-sizing: border-box;
            font-size: 16px;
            color: #000000;
          }


          .comment-items {
            padding: 13px;
            font-weight: 500;
            font-size: 12px;
            color: #000000;

            > img {
              width: 19px;
              height: 19px;
              vertical-align: middle;
            }

            .info {
              margin: 0 8px;

              > .name {
              }

              > .time {
                font-weight: 400;
                font-size: 12px;
                color: #919191;
              }
            }

            .content {
              font-weight: 400;
              color: #919191;
            }
          }
        }

        // 右侧下方固定不滚动的部分
        .right-info-footer {
          border-top: 1px solid #eee;
          flex-shrink: 0;
          height: 96px;
          // 评论部分
          .input-group {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EDEDED;

            .input {
              height: 48px;
              border: none;
              outline: none;
              flex: 1;
              padding-left: 10px;
            }

            .btn {
              cursor: pointer;
              width: 100px;
              text-align: center;

              .anticon {
                font-size: 20px;
                color: #333;
              }
            }
          }

          // 关注部分
          .follow {
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;

            .text {
              color: #4D4D4D;
              font-size: 16px;
              // 关注人数
              span {
                line-height: 16px;
                height: 16px;
                display: inline-block;
                background-color: #D9D9D9;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                border-radius: 4px;
                padding: 0 5px;
                margin: 0 5px;
              }
            }

            // 关注按钮
            .action {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 0 20px;

              .anticon {
                margin-right: 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

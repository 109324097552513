<template>
  <div style="padding: 0 20px;">
    <!--<div class="detail-title">审批流程</div>-->
    <div v-if="approvalList.length === 0" style="margin-top: 20px">
      <edp-empty></edp-empty>
    </div>
    <div class="detail-items">
      <div v-for="(item, index) in approvalList" :key="index">
        <div class="detail-item-1">
          <div class="top d-flex a-i-center">
            <div>{{ item.step_user || item.step_role_name }}：</div>
            <div class="status" :class="item.step_status_name==='不通过'?'nopass':'pass'">
              {{ item.step_status_name }}
            </div>
            <div>{{ item.update_time }}</div>
          </div>
          <div class="bottom d-flex">
            <div>审批意见</div>
            <div style="word-break: break-all;flex: 1;">{{ item.step_remark }}</div>
          </div>
        </div>
        <div class="detail-item-2 d-flex a-i-center" v-if="item.is_host  === '1'">
          <div class="name">审批：</div>
          <a-button type="primary" size="small" :auto-insert-space-in-button="false"
                    @click="showApprovalModal(item, '100')">
            通过
          </a-button>
          <a-button size="small" :auto-insert-space-in-button="false" @click="showApprovalModal(item,'-1')">
            不通过
          </a-button>
        </div>
      </div>
    </div>
    <approvalModal ref="approvalModal" @change="getApproval"></approvalModal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";
import approvalModal from "@/components/approvalModal.vue";

export default {
  name: "approval",
  data() {
    return {
      approvalList: []
    }
  },
  components: { approvalModal },
  props: {
    approvalData: {
      type: Object
    }
  },
  watch: {
    approvalData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getApproval()
        } else {
          this.approvalList = []
        }
      }
    }
  },
  created() {
  },
  methods: {
    // 查询审批流
    getApproval() {
      fetch('post', '/prj/wfdelivery/retrieve/bydelivery', {
        deliveryoid: this.approvalData.oid
      }).then((res) => {
        if (res.status === 0) {
          this.approvalList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showApprovalModal(item, step_status) {
      this.$refs.approvalModal.open(step_status, {
        ...this.approvalData,
        step_seq: item.step_seq,
        step_remark: item.step_remark,
        is_end: item.is_end,
      })
    }
  }
}
</script>

<style scoped lang="less">
.detail-title {
  //padding-left: 16px;
  height: 55px;
  line-height: 55px;
  box-sizing: border-box;
  border-bottom: 1px solid #CACACA;
  font-size: 14px;
  color: #000000;
}

.detail-items {
  //padding: 13px 13px;

  .detail-item-1 {
    margin-bottom: 14px;

    .top {
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
      margin-bottom: 9px;

      > div {
        margin-right: 5px;
      }

      > div:nth-last-child(1) {
        margin-right: 0;
        color: rgba(144, 144, 144, 1);
      }

      .status {
        color: #24B47E;
        background: #E9F7F2;
        padding: 0 5px;
      }

      .status.pass {
        background-color: #E0F7F0;
        color: #2ECC74;
      }

      .status.nopass {
        color: #EA0D0D;
        background: #FDE6E6;
      }
    }

    .bottom {
      font-size: 12px;
      color: #909090;
      font-weight: 500;
      align-items: baseline;
      height: 100%;
      position: relative;
      border-left: 4px solid #eee;

      > div:nth-last-child(1) {
        width: 205px;
        color: var(--primary-color);
        font-weight: 500;
        margin-left: 10px;
      }

      > div:nth-of-type(1) {
        width: 70px;
        margin-left: 15px;
      }
    }
  }

  .detail-item-2 {
    font-size: 12px;
    color: #000000;
    margin-bottom: 14px;

    > button {
      margin-right: 13px;
    }

    > button:nth-last-child(1) {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }
}
</style>
